import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/estimations`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [
    "organization",
    "creator",
    "customer",
    "vehicle",
    "inspection",
    "vehicle",
    "validatedBy",
    "approvedBy",
    "startedApproveBy",
    "endedApproveBy",
    "startedComponentsBy",
    "startedTimesBy",
    "endedComponentsBy",
    "endedTimesBy",
    "completedBy",
    "canceledBy",
    "reviewedBy",
    "deniedBy",
    "items",
    "items.organization",
    "approver",
    "allowedLocations",
    // "items.evaluation",
  ];

  return axios
    .get(`${url}/estimations/${id}?include=${includes.join(",")}`, options)
    .then((response) => {
      let estimation = jsona.deserialize(response.data);
      delete estimation.links;
      return estimation;
    });
}

function add(evaluation) {
  const payload = jsona.serialize({
    stuff: evaluation,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/estimations`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(evaluation) {
  const payload = jsona.serialize({
    stuff: evaluation,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/estimations/${evaluation.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/estimations/${id}`, options);
}

function cancel(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [];

  return axios
    .post(`${url}/estimations/${id}/cancel`, {}, options)
    .then((response) => {
      let request = jsona.deserialize(response.data);
      delete request.links;
      return request;
    });
}

function valid(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [];

  return axios
    .post(`${url}/estimations/${id}/valid`, {}, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function startComponents(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [];

  return axios
    .post(`${url}/estimations/${id}/start-components`, {}, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function endComponents(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [];

  return axios
    .post(`${url}/estimations/${id}/end-components`, {}, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function startTimes(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [];

  return axios
    .post(`${url}/estimations/${id}/start-times`, {}, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function endTimes(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [];

  return axios
    .post(`${url}/estimations/${id}/end-times`, {}, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function startApprove(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [];

  return axios
    .post(`${url}/estimations/${id}/start-approve`, {}, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function endApprove(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [];

  return axios
    .post(`${url}/estimations/${id}/end-approve`, {}, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function deny(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [];

  return axios
    .post(`${url}/estimations/${id}/deny`, {}, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function review(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [];

  return axios
    .post(`${url}/estimations/${id}/review`, {}, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function deleteItem(itemId) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/evaluation-items/${itemId}`, options);
}

function takeOverComponents(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [];

  return axios
    .post(
      `${url}/estimations/${id}/take-over/components?include=${includes.join(
        ","
      )}`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function takeOverTimes(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [];

  return axios
    .post(
      `${url}/estimations/${id}/take-over/times?include=${includes.join(",")}`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  cancel,
  startComponents,
  endComponents,
  startTimes,
  endTimes,
  startApprove,
  endApprove,
  valid,
  deny,
  review,
  deleteItem,
  takeOverComponents,
  takeOverTimes,
};
