<template>
  <div class="wrapper">
    <div class="elite-content_body_inner_header">
      <div class="elite-content_body_inner_header_left">
        <svg
          width="17"
          height="19"
          viewBox="0 0 17 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4772 9.89875V6.72203L14.5569 6.26483C14.4701 6.0109 14.3671 5.76277 14.2487 5.52196L15.2836 3.84033L13.0373 1.59403L11.3556 2.6289C11.1148 2.51047 10.8667 2.40754 10.6128 2.32073L10.1556 0.400391H6.9789L6.52167 2.32073C6.26775 2.40754 6.01963 2.51047 5.77883 2.6289L4.0972 1.59403L1.85086 3.84033L2.8857 5.52196C2.76728 5.76277 2.66434 6.0109 2.57753 6.26483L0.657227 6.72199V9.89872L2.57756 10.356C2.66438 10.6099 2.76731 10.858 2.88574 11.0988L1.84601 12.7884L3.40354 14.2802C3.23763 14.7776 3.15311 15.2985 3.15327 15.8229V18.1539H14.0515V15.8229C14.0515 15.3129 13.9715 14.8104 13.8174 14.3311L15.2759 12.768L14.2488 11.0989C14.3672 10.858 14.4701 10.6099 14.5569 10.356L16.4772 9.89875ZM12.9968 17.0993H4.20794V15.8229C4.20794 15.0914 4.41634 14.3792 4.81064 13.7631C5.5165 12.6602 6.71966 12.0017 8.0291 12.0017H9.17566C10.4851 12.0017 11.6883 12.6602 12.3941 13.7631C12.788 14.3778 12.9972 15.0927 12.9968 15.8229V17.0993ZM8.60238 10.9031C7.25758 10.9031 6.16347 9.809 6.16347 8.4642C6.16347 7.11939 7.25758 6.02528 8.60238 6.02528C9.94719 6.02528 11.0413 7.11939 11.0413 8.4642C11.0413 9.809 9.94719 10.9031 8.60238 10.9031ZM13.9564 12.6362L13.3432 13.2933C13.3232 13.2603 13.3033 13.2271 13.2824 13.1946C12.6776 12.2495 11.7863 11.5607 10.7602 11.2098C11.5731 10.5696 12.0959 9.57683 12.0959 8.4642C12.0959 6.53785 10.5287 4.97061 8.60235 4.97061C6.676 4.97061 5.10876 6.53785 5.10876 8.4642C5.10876 9.57683 5.6316 10.5696 6.44446 11.2098C5.41838 11.5607 4.52712 12.2495 3.92226 13.1946C3.90602 13.2199 3.89073 13.2458 3.87498 13.2714L3.1905 12.6158L4.1024 11.134L3.41265 9.4707L1.71189 9.06571V7.55504L3.41268 7.15008L4.10244 5.4868L3.18565 3.99705L4.25385 2.92885L5.74364 3.84563L7.40692 3.15588L7.81191 1.45506H9.32258L9.72754 3.15585L11.3908 3.84563L12.8806 2.92885L13.9488 3.99705L13.032 5.4868L13.7217 7.15008L15.4225 7.55504V9.06571L13.7217 9.47066L13.032 11.1339L13.9564 12.6362Z"
            fill="#000"
          />
        </svg>

        <h1>{{ $t("APPS.ADMIN_MODULE") }}</h1>
      </div>
      <!--<div class="elite-content_body_inner_header_right">
        <button><i class="far fa-ellipsis-h"></i></button>
      </div>-->
    </div>
    <div class="elite-content_body_inner_container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
