import permissions from "../constants/permissions";
import InspectproModuleLayout from "@/views/Pages/InspectproModule/InspectproModuleLayout.vue";
import InspectproModuleMenu from "@/views/Pages/InspectproModule/InspectproModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

const InspectproPage = () =>
  import("@/views/Pages/InspectproModule/InspectproPage.vue");

const ListInspectionPage = () =>
  import(
    "@/views/Pages/InspectproModule/InspectionManagement/ListInspectionPage.vue"
  );

const ListInspectionFormPage = () =>
  import(
    "@/views/Pages/InspectproModule/InspectionFormManagement/ListInspectionFormPage.vue"
  );

const DemoInspectionFormPage = () =>
  import(
    "@/views/Pages/InspectproModule/InspectionFormManagement/DemoInspectionFormPage.vue"
  );

const ListEstimatePage = () =>
  import(
    "@/views/Pages/InspectproModule/EstimateManagement/ListEstimatePage.vue"
  );

let inspectionManagementPages = {
  path: "inspections",
  components: {
    default: InspectproModuleLayout,
    SidebarSecondLevelMenu: InspectproModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Inspections",
      components: { default: ListInspectionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_MODULE_INSPECTIONS,
        hasLevel2: true,
      },
    },
  ],
};

let inspectionFormManagementPages = {
  path: "inspection-forms",
  components: {
    default: InspectproModuleLayout,
    SidebarSecondLevelMenu: InspectproModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List InspectionForms",
      components: { default: ListInspectionFormPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_INSPECTION_FORMS,
        hasLevel2: true,
      },
    },
    {
      path: ":id/demo",
      name: "Demo InspectionForm",
      components: { default: DemoInspectionFormPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_INSPECTION_FORMS,
        hasLevel2: true,
      },
    },
  ],
};

let estimateManagementPages = {
  path: "estimates",
  components: {
    default: InspectproModuleLayout,
    SidebarSecondLevelMenu: InspectproModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Estimates",
      components: { default: ListEstimatePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_MODULE_ESTIMATIONS,
        hasLevel2: true,
      },
    },
  ],
};

const inspectproModuleRoutes = [
  {
    path: "",
    components: {
      default: InspectproModuleLayout,
      SidebarSecondLevelMenu: InspectproModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Inspectpro Page",
        components: { default: InspectproPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  inspectionFormManagementPages,
  inspectionManagementPages,
  estimateManagementPages,
];

export default inspectproModuleRoutes;
