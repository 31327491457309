import { i18n } from "./i18n";

const objectDenomination = {
  install: (Vue) => {
    Vue.prototype.$objectDenomination = (object) => {
      if (!object) {
        return "";
      }

      if (
        [
          "products",
          "warehouse-products",
          "supplier-products",
          "warehouses",
          "resellers",
          "roles",
          "organizations",
          "locations",
          "imports",
          "files",
          "inspection-forms",
          "organizations",
          "resellers",
          "packages",
        ].includes(object.type)
      ) {
        return `${object.name}`;
      }
      if (object.type === "customers") {
        return `${object.customer_name}`;
      }
      if (object.type === "suppliers") {
        return `${object.company_name}`;
      }
      if (object.type === "users") {
        return `${object.firstname} ${object.lastname}`;
      }
      if (object.type === "contacts") {
        return `${object.title} ${object.firstname} ${object.lastname}`;
      }
      if (
        [
          "purchases-orders",
          "purchases-invoices",
          "sales-orders",
          "sales-invoices",
          "requests",
          "inspections",
          "estimates",
          "evaluations",
          "estimations",
          "reseller-invoices",
          "reseller-payments",
          "reseller-products",
          "reseller-services",
          "subscriptions",
        ].includes(object.type)
      ) {
        return `${object.code}`;
      }
      if (["vehicles"].includes(object.type)) {
        let fuel_type = object.fuel_type ?? null;

        if (fuel_type) {
          fuel_type = i18n.tc(`FUEL_TYPES.${fuel_type?.toUpperCase()}`)
            ? i18n.t(`FUEL_TYPES.${fuel_type?.toUpperCase()}`)
            : fuel_type;
        }
        let transmission = object.transmission ?? null;

        if (transmission) {
          transmission = i18n.tc(`TRANSMISSIONS.${transmission?.toUpperCase()}`)
            ? i18n.t(`TRANSMISSIONS.${transmission?.toUpperCase()}`)
            : transmission;
        }

        return `${object.model_year} ${object.make ?? ""} ${
          object.model ?? ""
        }`;
      }
      return "NO DENOMINATION PLEASE UPDATE PLUGIN objectDenomination.js";
    };
  },
};

export default objectDenomination;
