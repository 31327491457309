import service from "@/store/services/inspections-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  list: {},
  inspection: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, inspection) => {
    state.inspection = inspection;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((inspection) => {
      commit("SET_RESOURCE", inspection);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((inspection) => {
      commit("SET_RESOURCE", inspection);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((inspection) => {
      commit("SET_RESOURCE", inspection);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  complete({ commit, dispatch }, { inspectionId, data }) {
    return service
      .complete(inspectionId, data, this.$axios)
      .then((request) => {});
  },

  cancel({ commit, dispatch }, params) {
    return service.cancel(params, this.$axios).then((request) => {});
  },

  getReport({ commit, dispatch }, params) {
    return service.getReport(params, this.$axios);
  },

  assign({ commit, dispatch }, { inspectionId, data }) {
    return service.assign(inspectionId, data, this.$axios).then((request) => {
      commit("SET_RESOURCE", request);
    });
  },

  approve({ commit, dispatch }, { inspectionId, data }) {
    return service
      .approve(inspectionId, data, this.$axios)
      .then((inspection) => {
        commit("SET_RESOURCE", inspection);
      });
  },

  start({ commit, dispatch }, { inspectionId, data }) {
    return service.start(inspectionId, data, this.$axios);
  },

  takeOver({ commit, dispatch }, { inspectionId, data }) {
    return service.takeOver(inspectionId, data, this.$axios);
  },

  resetSection({ commit, dispatch }, { inspectionId, data }) {
    return service.resetSection(inspectionId, data, this.$axios);
  },

  setSectionGood({ commit, dispatch }, { inspectionId, data }) {
    return service.setSectionGood(inspectionId, data, this.$axios);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  inspection: (state) => state.inspection,
  url: (state) => state.url,
  dropdown: (state) => {
    return state.list.map((inspection) => ({
      id: inspection.id,
      code: inspection.code,
    }));
  },
};

const inspections = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default inspections;
