export default {
  ESTIMATES_LIST: "Liste des estimations",
  ADD_ESTIMATE: "Ajouter une estimation",
  VIEW_ESTIMATE: "Estimation",
  EDIT_ESTIMATE: "Modifier une estimation",
  ESTIMATE_ADDED: "Estimation ajoutée",
  ESTIMATE_UPDATED: "Estimation mise à jour",
  DELETE_THIS_ESTIMATE: "Supprimer cette Estimation?",
  ESTIMATE_DELETED: "Estimation supprimée",
  ESTIMATE_STOCK: "Stock",
  ESTIMATE_TYPE: "Type",
  CREATOR: "Créateur",
  ALL: "Toutes",
  APPROVED_ALL: "Tout approuver",
  ESTIMATE_STATUS_DRAFT: "Brouillon",
  ESTIMATE_STATUS_CANCELED: "Annulée",
  ESTIMATE_STATUS_COMPLETED: "Complétée",
  ESTIMATE_STATUS_ASSIGNED: "Assignée",
  ESTIMATE_STATUS_APPROVED: "Approuvée",
  ESTIMATE_STATUS_REVIEWED: "Revue",
  ESTIMATE_STATUS_DENIED: "Refusée",
  ESTIMATE_STATUS_PENDING_COMPONENTS: "En attente pièces",
  ESTIMATE_STATUS_IN_PROGRESS_COMPONENTS: "En cours pièces",
  ESTIMATE_STATUS_PENDING_TIMES: "En attente temps",
  ESTIMATE_STATUS_IN_PROGRESS_TIMES: "En cours temps",
  ESTIMATE_STATUS_PENDING_APPROVE: "En attente d'approbation",
  ESTIMATE_STATUS_IN_PROGRESS_APPROVE: "En cours d'approbation",
  ESTIMATE_STATUS_PENDING: "En attente",
  ESTIMATE_STATUS_IN_PROGRESS: "En cours",
  ESTIMATE_TYPES_STATUS_PENDING: "En attente ",
  ESTIMATE_TYPES_STATUS_IN_PROGRESS: "En cours ",
  ESTIMATE_TYPES_STATUS_COMPLETED: "Terminée ",
  APPROVED_AT: "Approuvée le",
  APPROVED_BY: "Approuvée par",
  ASSIGNED_BY: "Assignée par",
  CANCELED_BY: "Annulée par",
  CANCELED_AT: "Annulée le",
  COMPLETED_BY: "Complétée par",
  ASSIGNED_TO: "Assignée à",
  ASSIGNED_AT: "Assignée le",
  REVIEWED_BY: "Revue par",
  REVIEWED_AT: "Revue le",
  DENIED_BY: "Refusée par",
  DENIED_AT: "Refusée le",
  ESTIMATE_CLIENT: "Client",
  ASSIGNED_TO_ME: "Assignées à moi",
  CREATED_BY_ME: "Créées par moi",
  COMPLETED_AT: "Complétée le",
  ESTIMATE_CLIENT: "Client",
  ESTIMATE_DEPARTMENT: "Département",
  ESTIMATE_ALL_DEPARTMENTS: "Départements",
  ESTIMATE_ASSIGN_TO: "Assignée à",
  ESTIMATE_TYPE: "Type d'estimation",
  ESTIMATE_DETAIL: "Détails de l'estimation",
  ESTIMATE_ITEM: "Item",
  ESTIMATE_ITEM_DETAIL: "Détail",
  ESTIMATE_ITEM_STATE: "État",
  ESTIMATE_ITEM_PART: "Pièce",
  ESTIMATE_ITEM_PART_NUMBER: "Numéro de pièce",
  ESTIMATE_ITEM_MANUAL_LABOR: "Main-d'oeuvre",
  ESTIMATE_ITEM_TIME: "Temps",
  ESTIMATE_ITEM_HOURLY_RATE: "Taux horaire",
  ESTIMATE_ITEM_APPROVED: "Approuver",
  ESTIMATE_ITEM_REVIEW: "Réviser",
  ESTIMATE_ITEM_REFUSED: "Refuser",
  ESTIMATE_ITEM_STATUS_APPROVED: "Approuver",
  ESTIMATE_ITEM_STATUS_REVIEWED: "À réviser",
  ESTIMATE_ITEM_STATUS_DENIED: "Refuser",
  ESTIMATE_ITEM_CONDITION_TO_REPLACE: "À remplacer",
  ADD_ESTIMATE_ITEM_: "Ajouter un item",
  TO_REPLACE: "À remplacer",
  ESTIMATE_ITEM_CONDITION_TO_REPAIR: "À réparer",
  TO_REPAIR: "À réparer",
  VEHICLE_VIN: "Vin du véhicule",
  SAVE_AND_SENT: "Sauvegarder et envoyer",
  REMOVE_ITEM: "Retirer",
  ESTIMATE_ITEM_ADDED: "Élément ajouté",
  DELETE_THIS_ESTIMATE_ITEM: "Supprimer cet élément de l'estimation?",
  DELETE_THIS_ITEM_FROM_ESTIMATE:
    "Voulez-vous supprimer cet élément de l'estimation?",
  ESTIMATE_ITEM_DELETED: "Élément supprimé",
  APPROVE_ESTIMATE: "Approuver l'estimation",
  ESTIMATE_ASSIGNED: "Estimation assignée",
  ESTIMATE_APPROVED: "Estimation approuvée",
  ASSIGN_ESTIMATE: "Assigner l'estimation",
  ESTIMATE_CANCELED: "Estimation annulée",
  CANCEL_ESTIMATE: "Annuler l'estimation",
  CANCEL_THIS_ESTIMATE: "Voulez-vous annuler cette estimation?",
  ESTIMATE_DENIED: "Estimation refusée",
  DENY_ESTIMATE: "Refuser l'estimation",
  ESTIMATE_COMPLETED: "Estimation terminée",
  COMPLETE_ESTIMATE: "Terminer l'estimation",
  ESTIMATE_REVIEWED: "Estimation à revoir",
  REVIEW_ESTIMATE: "Mettre à revoir l'estimation",
  NEXT_STEP: "Prochaine étape",
  SAVE_VEHICLE: "Enregistrer le véhicule",

  START_COMPONENTS_ESTIMATE: "Démarrer l'édition des pièces de l'estimation",
  START_THIS_COMPONENTS_ESTIMATE:
    "Voulez-vous démarrer l’estimation des pièces?",
  ESTIMATE_STARTED_COMPONENTS: "Edition des pièces de l'estimation commencée",
  STARTED_COMPONENTS_AT: "Edition des pièces débutée le",
  STARTED_COMPONENTS_BY: "Edition des pièces débutée par",

  END_COMPONENTS_ESTIMATE: "Terminer l'estimation pièces",
  END_THIS_COMPONENTS_ESTIMATE: "Voulez-vous terminer l’estimation des pièces?",
  END_THIS_COMPONENTS_ESTIMATE_WITHOUT_COMPONENTS:
    "Voulez-vous terminer l’estimation sans pièces?",
  ESTIMATE_ENDED_COMPONENTS: "Edition des pièces de l'estimation terminée",
  ENDED_COMPONENTS_AT: "Edition des pièces terminée le",
  ENDED_COMPONENTS_BY: "Edition des pièces terminée par",

  START_TIMES_ESTIMATE: "Démarrer l'édition des temps de l'estimation",
  START_THIS_TIMES_ESTIMATE: "Voulez-vous démarrer l’estimation des temps?",
  ESTIMATE_STARTED_TIMES: "Edition des temps de l'estimation commencée",
  STARTED_TIMES_AT: "Edition des temps débutée le",
  STARTED_TIMES_BY: "Edition des temps débutée par",

  END_TIMES_ESTIMATE: "Terminer l'estimation mains d’oeuvre",
  END_THIS_TIMES_ESTIMATE: "Voulez-vous terminer l’estimation des temps?",
  ESTIMATE_ENDED_TIMES: "Edition des temps de l'estimation terminée",
  ENDED_TIMES_AT: "Edition des temps terminée le",
  ENDED_TIMES_BY: "Edition des temps terminée par",

  START_APPROVE_ESTIMATE: "Démarrer l'approbation de l'estimation",
  START_THIS_APPROVE_ESTIMATE:
    "Voulez-vous démarrer l’approbation des estimations?",
  ESTIMATE_STARTED_APPROVE: "Approbation de l'estimation commencée",
  STARTED_APPROVE_AT: "Approbation débutée le",
  STARTED_APPROVE_BY: "Approbation débutée par",

  END_APPROVE_ESTIMATE: "Terminer l'approbation",
  END_THIS_APPROVE_ESTIMATE:
    "Voulez-vous terminer l’approbation des estimations?",
  ESTIMATE_ENDED_APPROVE: "Approbation de l'estimation terminée",
  ENDED_APPROVE_AT: "Approbation terminée le",
  ENDED_APPROVE_BY: "Approbation terminée par",

  VALID_ESTIMATE: "Valider l'estimation",
  ESTIMATE_VALIDATED: "Estimation validée",
  VALIDATED_AT: "Validée le",
  VALIDATED_BY: "Validée par",
  VALIDATE_THIS_ESTIMATE: "Voulez-vous valider cette estimation?",

  TIMES: "Main d’oeuvre",
  COMPONENTS: "Pièces",
  TIMES_STATUS: "État des Temps",
  COMPONENTS_STATUS: "État des Pièces",

  ESTIMATE_IN_PROGRESS_WARNING: "L’estimation est en cours de modification ",

  COMPONENTS_ESTIMATOR: "Estimateur de pieces",
  TIMES_ESTIMATOR: "Estimateur de la main d’oeuvre",

  START_ESTIMATION: "Démarrer l’estimation",
  END_ESTIMATION: "Terminer l’estimation",

  ESTIMATE_WITHOUT_COMPONENTS: "Estimer sans les pieces",
  ESTIMATE_WITHOUT_TIMES: "Estimer sans les temps",
  NO_COMPONENTS_ADDED: "Aucune pièces n’est ajoutée pour cette estimation",
  OTHER_WORK_ON_ESTIMATE:
    "Un autre utilisateur travaille déjà sur cette estimation",

  GO_TO_TIMES: "Aller aux mains d’oeuvre",

  BACK_TO_COMPONENTS: "Revenir aux pièces",
  GO_TO_APPROVE: "Aller à l’approbation",

  BACK_TO_TIMES: "Revenir aux mains d’oeuvre",
  CODE: "# estimation",

  MECHANICS: "Mécaniciens",
  OFFICIAL_MECHANIC: "Mécanicien officiel",
  CREATED_AT: "Créée le",
  CANCELED: "Annulées",
  UPDATED_AT: "Mise à jour",
  COMPLETED: "Complétées",

  TAKE_OVER_ESTIMATE: "S’approprier l’estimation",
  TAKE_OVER_THIS_ESTIMATE:
    "Cette estimation est en cours par {name}. \n Voulez-vous vous l’approprier?",
  YES_TAKE_OVER: "Oui, m’approprier l’estimation",
  COMPONENTS_TOOK_OVER: "Appropriation des pièces <u>effectuée</u>",
  TIMES_TOOK_OVER: "Appropriation des main d’oeuvre <u>effectuée</u>",

  CHANGE_COMPONENTS_MANAGER: "Changer le responsable des pièces",
  CHANGE_TIMES_MANAGER: "Changer le responsable de la main d’oeuvre",

  SHOW_ESTIMATE: "Voir l’estimation",
  ESTIMATE_ALREADY_EXIST: "Une estimation est déjà en cours pour ce véhicule",

  REVIEW_ALERT:
    "L’estimation sera remise <b>en cours</b> pour le traitement des révisions",

  INSPECTION_REPORT: "Rapport d'inspection",
};
