<template>
  <div class="wrapper">
    <div class="elite-content_body_inner_header">
      <div class="elite-content_body_inner_header_left">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.7498 15.7373L11.9351 16.9227C12.0404 17.0245 12.1815 17.0807 12.3279 17.0795C12.4743 17.0782 12.6143 17.0195 12.7178 16.9159C12.8213 16.8124 12.8801 16.6724 12.8813 16.5259C12.8826 16.3795 12.8263 16.2385 12.7246 16.1332L11.5873 14.9957C12.0688 14.4008 12.331 13.6583 12.3299 12.8928C12.3299 11.044 10.8285 9.54248 8.97988 9.54248C7.13068 9.54248 5.62988 11.044 5.62988 12.8928C5.63026 13.4902 5.79027 14.0766 6.09334 14.5913C6.39641 15.106 6.83153 15.5304 7.35366 15.8205C7.87579 16.1105 8.46595 16.2558 9.06305 16.2412C9.66015 16.2265 10.2425 16.0526 10.7498 15.7373ZM8.97988 10.6593C10.2127 10.6593 11.2132 11.6605 11.2132 12.8928C11.2132 14.1258 10.2127 15.1264 8.97988 15.1264C7.74708 15.1264 6.74655 14.1258 6.74655 12.8928C6.74655 11.6605 7.74708 10.6593 8.97988 10.6593Z"
            fill="#000"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.77389 7.08721L16.2906 7.10061V11.0624C16.2898 11.1277 16.2758 11.1923 16.2494 11.252C16.223 11.3118 16.1848 11.3657 16.137 11.4103C16.0272 11.5127 15.8823 11.569 15.7322 11.5677H14.0902C13.9464 11.5742 13.8107 11.6358 13.7112 11.7398C13.6118 11.8438 13.5563 11.9822 13.5563 12.1261C13.5563 12.27 13.6118 12.4084 13.7112 12.5124C13.8107 12.6164 13.9464 12.6781 14.0902 12.6845H15.7322C16.1733 12.6845 16.5954 12.5159 16.907 12.2188C17.0644 12.0701 17.19 11.8909 17.276 11.6921C17.362 11.4932 17.4067 11.279 17.4072 11.0624V4.14951C17.4072 3.7162 17.2286 3.29908 16.9064 2.99308C16.589 2.69301 16.1685 2.52631 15.7317 2.52738L9.59503 2.52962C9.41636 2.52962 9.24663 2.45312 9.13943 2.31687L8.13778 1.04039C7.97835 0.839838 7.77556 0.678021 7.54464 0.567093C7.31371 0.456164 7.06066 0.399006 6.80448 0.399913H2.33223C1.39981 0.399913 0.657227 1.13364 0.657227 2.02204V11.0624C0.657227 11.9508 1.39981 12.6845 2.33223 12.6845H3.99662C4.1447 12.6845 4.28671 12.6257 4.39142 12.521C4.49613 12.4163 4.55495 12.2742 4.55495 12.1261C4.55495 11.978 4.49613 11.836 4.39142 11.7313C4.28671 11.6266 4.1447 11.5677 3.99662 11.5677H2.33223C2.03128 11.5677 1.77389 11.3494 1.77389 11.0624V7.08721ZM16.2906 5.98382V4.14951C16.2898 4.08411 16.2757 4.01954 16.2492 3.95974C16.2228 3.89993 16.1844 3.84614 16.1365 3.80163C16.0269 3.69918 15.8822 3.6428 15.7322 3.64417L9.59503 3.6464C9.33873 3.64751 9.08553 3.59045 8.85448 3.47951C8.62343 3.36857 8.42057 3.20664 8.26117 3.00592L7.25952 1.73C7.20468 1.66241 7.13525 1.60812 7.05645 1.57118C6.97764 1.53424 6.8915 1.51561 6.80448 1.5167H2.33223C2.03128 1.5167 1.77389 1.73503 1.77389 2.02204V5.97042L16.2906 5.98382Z"
            fill="#000"
          />
        </svg>

        <h1>{{ $t("APPS.FILE_EXPLORER_MODULE") }}</h1>
      </div>
      <!--<div class="elite-content_body_inner_header_right">
        <button><i class="far fa-ellipsis-h"></i></button>
      </div>-->
    </div>
    <div class="elite-content_body_inner_container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
